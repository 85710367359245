import React from "react"
import { graphql, Link } from "gatsby"
import statWinner from "../../js/statWinner"
//import formatNumComma from "../../js/formatNumComma"
import gamesPerStat from "../../js/gamesPerStat"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'

class OutsideBoxPage extends React.Component {

  state = {
    showTopLevel: false,
  };

  manageTopLevelToggle = () => {
    this.setState(state => ({ showTopLevel: !state.showTopLevel }));
  };

  render() {

    const pageTitle = "Outside the Box Goals"

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    const subStat = (x, comp) => {
      return x === "m" ? (
        parseFloat(mdata.filter(d => d.node.competition === comp).map(d => d.node.apps)[0] / mdata.filter(d => d.node.competition === comp).map(d => d.node.outsideBox)[0]).toFixed(1)
      ) : (
        parseFloat(rdata.filter(d => d.node.competition === comp).map(d => d.node.apps)[0] / rdata.filter(d => d.node.competition === comp).map(d => d.node.outsideBox)[0]).toFixed(1)
      )
    }

    const comps = [
      {
        "comp": "All Time Career",
        "mSubStat": subStat('m', 'All Time Career'),
        "rSubStat": subStat('r', 'All Time Career'),
        "btnText": "Yearly Breakdown"
      },
      {
        "comp": "All Time Career Europe",
        "title": "All Time Career *",
        "mSubStat": subStat('m', 'All Time Career Europe'),
        "rSubStat": subStat('r', 'All Time Career Europe'),
        "btnText": "Yearly Breakdown",
        "topLevel": true
      },
      {
        "comp": "All Time Club",
        "mSubStat": subStat('m', 'All Time Club'),
        "rSubStat": subStat('r', 'All Time Club'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time Club Europe",
        "title": "All Time Club *",
        "mSubStat": subStat('m', 'All Time Club Europe'),
        "rSubStat": subStat('r', 'All Time Club Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "League",
        "mSubStat": subStat('m', 'All Time League'),
        "rSubStat": subStat('r', 'All Time League'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time League Europe",
        "title": "League *",
        "mSubStat": subStat('m', 'All Time League Europe'),
        "rSubStat": subStat('r', 'All Time League Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "Champions League",
        "mSubStat": subStat('m', 'All Time Europe'),
        "rSubStat": subStat('r', 'All Time Europe'),
        "btnText": "Season Breakdown",
        "topLevel": "both"
      },
      {
        "comp": "Other Club Cups",
        "mSubStat": subStat('m', 'All Time Cups'),
        "rSubStat": subStat('r', 'All Time Cups'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time Cups Europe",
        "title": "Other Club Cups *",
        "mSubStat": subStat('m', 'All Time Cups Europe'),
        "rSubStat": subStat('r', 'All Time Cups Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "International",
        "mSubStat": subStat('m', 'All Time Internationals'),
        "rSubStat": subStat('r', 'All Time Internationals'),
        "btnText": "Yearly Breakdown",
        "topLevel": "both"
      },
    ]

    let [{
      outsideBox: mAllTimeOutside,
      freeKicks: mAllTimeFreeKicks,
      apps: mAllTimeApps,
    }] = mdata.filter(d => d.node.competition === "All Time Career").map(d => d.node)
  
    let [{
      outsideBox: rAllTimeOutside,
      freeKicks: rAllTimeFreeKicks,
      apps: rAllTimeApps,
    }] = rdata.filter(d => d.node.competition === "All Time Career").map(d => d.node)


    return (

      <Layout>
        <SEO
          title={`Outside the Box Goals - All Messi and Ronaldo Goals From Outside the Box`}
          canonicalPath={`/detailed-stats/outside-box-goals/`}
        />

        <h1>Outside the Box Goals (open play) <span className="sr-only">- All Messi and Ronaldo Goals From Outside the Box</span></h1>

        
        <div className="w-full p-4 max-w-xs mx-auto mt-4 sm:mt-6 md:mt-8 lg:mt-10 xl:mt-16 2xl:mt-20">
          <InfoBar classes="w-full justify-center" msg="Outside the box goals do not include direct free kick goals" />
        </div>

        <div className="flex items-center justify-center max-w-xs mb-2 xl:mb-4 mx-auto px-2" onChange={this.manageTopLevelToggle}>
          <label className={`flex items-center cursor-pointer px-3 sm:px-5 pt-3 pb-4 bg-noir-lighten10 border ${this.state.showTopLevel ? 'border-highlight' : 'border-noir-lighten10'}`}>
            <input type="checkbox" checked={this.state.showTopLevel ? true : false} />
            <span className="ml-2 text-xs sm:text-sm">Only include club stats for European clubs</span>
          </label>
        </div>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center`}>

        {comps.map(d => (
          <DetailedStatBars
            key={d.comp}
            data={this.props.data}
            competition={d.comp}
            title={d.title ? d.title : d.comp}
            topLevel={d.topLevel}
            showTopLevel={this.state.showTopLevel}
            playerTitleHidden={`${d.comp} ${pageTitle}`}
            statType="Outside the box goals (open play)"
            btnText={d.btnText}
            statView={['Totals', "Frequency"]}
            mSubStat={`scores from outside the box every ${d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-'} games`}
            rSubStat={`scores from outside the box every ${d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-'} games`}
            subStatW={statWinner(d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-', d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-', 'lowWins')}
          />
        ))}

        </div>

        <CopyBlock>
          <h2>Messi & Ronaldo Goals From Outside the Box</h2>

          <InfoBar />

          <p>First of all, all of the stats on this page do not include free kick goals unless otherwise stated. You can find everything you want to know about Messi and Ronaldo free kick stats over on the <Link to={`/detailed-stats/free-kicks/`} className="text-highlight underline">Free Kicks</Link> page.</p>

          <h3>Ronaldo forms a lasting reputation</h3>

          <p>It didn't take long for <strong className="text-ronaldo">Ronaldo</strong> to start showcasing his ability to shoot from distance, scoring some sensational goals from range during his time at Manchester United. <strong className="text-ronaldo">He scored 10 open play goals from outside the box in 6 seasons for Man Utd</strong> (excluding free kicks), some of which were truly spectacular.</p>

          <p>His most famous effort was an astonishing strike from almost 40 yards against Porto in the Champions League, which later earned him the coveted <strong className="text-highlight">Puskas Award</strong> for the best goal of 2009.</p>

          <div><iframe title="Ronaldo Puskas award goal vs Porto" className="my-8" width="560" height="315" src="https://www.youtube.com/embed/-NpR6jO2pi0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>

          <p>When Ronaldo moved to Real Madrid and began to enter his peak years, the number of open play goals he scored from outside the box increased, <strong className="text-ronaldo">hitting 29 during his 9 seasons at Madrid</strong>. However, the last few years have seen a gradual decline, managing 1 open play outside the box goal in his last Real season and <strong className="text-ronaldo">6 in his three seasons at Juventus</strong>.</p>
          
          <h3>Messi scores more from outside the box</h3>

          <p>Despite Ronaldo's reputation for long-distance goals, it's actually <strong className="text-messi">Messi</strong> who has scored more goals from outside the box during their respective careers, even having played {rAllTimeApps-mAllTimeApps} fewer games than his rival. <strong className="text-messi">Messi has {mAllTimeOutside} career open play goals from outside the box</strong>, whereas <strong className="text-ronaldo">Ronaldo has scored {rAllTimeOutside} outside the box goals</strong>.</p>

          <p><strong className="text-ronaldo">Ronaldo's most open play goals from outside the box in a season is 6</strong>, which he registered in the 11/12 season.</p>
          
          <p><strong className="text-messi">Messi has hit 6 or more from outside the box on six separate occasions</strong>; 09/10 (8), 10/11 (7), 12/13 (6), 13/14 (6), 16/17 (10) and 20/21 (6).</p>

          <p>Having scored more in less games, it also figures that Messi scores from outside the penalty area significantly more frequently than his counterpart. <strong className="text-messi">Messi scores from outside the box every {gamesPerStat(mAllTimeOutside, mAllTimeApps)} games</strong>, with <strong className="text-ronaldo">Ronaldo netting from outside the box every {gamesPerStat(rAllTimeOutside, rAllTimeApps)} games</strong>.</p>

          <h3>Long Range Goal Style</h3>

          <p>It's worth noting the contrasting style of Messi and Ronaldo's goals from outside the box. Messi's tend to be bent in from the edge of the D with controlled power. He is considerably less likely to shoot from long range, usually preferring to dribble or continue the build-up play until he's in a more likely scoring position.</p>

          <p>Ronaldo on the other hand is more partial to your typical "screamer", often attempting thunderous efforts from 25+ yards out. He is much more willing to hit speculative efforts, with the confidence that he has the ability and power to beat the keeper from almost anywhere.</p>

          <p>Overall, despite Ronaldo's reputation for powerful long range efforts, it's not really surprising that Messi has the better numbers in this category, and here's why...</p>
            
          <p>Messi is not a penalty box striker; he's a deep-lying forward who naturally looks for space around the edge of the box, and his curled efforts from the edge of the D are practically his trademark goal. Ronaldo on the other hand has increasingly morphed into your typical centre-forward, looking to finish moves in the penalty area rather than receiving the ball in deep positions.</p>

          <h3>Outside The Box Goals + Free Kick Goals</h3>

          <p>If we bundle in all outside the box goals and free kick goals together, the numbers are:</p>

          <ul className="list-inside list-disc">
            <li className="text-messi">Messi: {Number(mAllTimeOutside)+Number(mAllTimeFreeKicks)} goals ({mAllTimeFreeKicks} free kick goals + {mAllTimeOutside} goals from outside the box)</li>
            <li className="text-ronaldo">Ronaldo: {Number(rAllTimeOutside)+(Number(rAllTimeFreeKicks)-1)} goals ({Number(rAllTimeFreeKicks)-1} free kick goals + {rAllTimeOutside} goals from outside the box)</li>
          </ul>

          <p>If you’re looking for more stats on goals from outside the box, contact us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, and we’ll do our best to help.</p>

        </CopyBlock>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          freeKicks
          competition
          apps
          minsPlayed
          outsideBox
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          freeKicks
          competition
          apps
          minsPlayed
          outsideBox
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          outsideBox
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          outsideBox
        }
      }
    }
    allSheetMessiIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          outsideBox
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          outsideBox
        }
      }
    }
    allSheetMessiCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          outsideBox
        }
      }
    }
    allSheetRonaldoCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          outsideBox
        }
      }
    }
  }
`

export default OutsideBoxPage
